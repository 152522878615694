.aiAssistant {
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: #343541;
    color: #ECECF1;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 2rem;

    h2 {
        color: #ff4d4d;
        margin-bottom: 1rem;
    }

    p {
        color: #8E8EA0;
        margin-bottom: 2rem;
    }
}

.retryButton {
    padding: 0.75rem 1.5rem;
    background-color: #10A37F;
    border: none;
    border-radius: 0.375rem;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover {
        background-color: #0E906F;
    }
}

.sidebar {
    width: 300px;
    background-color: #202123;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    overflow-y: auto;
}

.categoryList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;

    .loading {
        color: #8E8EA0;
        text-align: center;
        padding: 2rem;
        font-style: italic;
    }
}

.categoryButton {
    text-align: left;
    padding: 0.75rem 1rem;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 0.5rem;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    
    &:hover {
        background-color: rgba(255,255,255,0.1);
        transform: translateY(-1px);

        svg {
            opacity: 1;
            transform: scale(1.1);
        }
    }
    
    &.selected {
        background-color: #343541;
        border-color: #10A37F;

        svg {
            color: #10A37F;
            opacity: 1;
        }
    }
}

.categoryTitle {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: currentColor;
        flex-shrink: 0;
        opacity: 0.9;
    }

    span {
        flex: 1;
    }
}

.categoryDescription {
    font-size: 0.8rem;
    color: #8E8EA0;
    line-height: 1.2;
}

.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 48rem;
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
}
.aiAssistant_advanced{
    .sidebar{
        padding-top: 155px;
        overflow: unset; 
        .categoryList{
            overflow-y: auto;
            padding-top: 0;
            &::-webkit-scrollbar {
                width: 6px; 
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #b6b6b6be;
                border-radius: 30px;
            }
            .categoryTitle{
                display: flex;
                gap: 10px;
                svg{
                    width: 20px;
                    height: auto;
                    font-size: 20px;
                    fill: #fff;
                }
            }
        }
    }
    .chatContainer_advanced{
        justify-content: flex-end;
        height: 100%;
        .messagesContainer{
            height: calc(100% - 230px) !important;
            flex: unset;
            padding-top: 0;
            &::-webkit-scrollbar {
                width: 6px;
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #b6b6b6be;
                border-radius: 30px;
            }
        }
    }
}


.messagesContainer {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.welcome {
    text-align: center;
    margin-top: 20vh;

    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    p {
        color: #8E8EA0;
        font-size: 1.125rem;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 1rem;
    }

    .agentName {
        color: #10A37F;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 1rem;
    }
}

.message {
    display: flex;
    padding: 1rem;
    border-radius: 0.5rem;
    
    &.userMessage {
        background-color: #444654;
    }
    
    &.aiMessage {
        background-color: #343541;
    }

    &.errorMessage {
        background-color: rgba(255, 77, 77, 0.1);
        border: 1px solid rgba(255, 77, 77, 0.2);

        .messageContent {
            color: #ff4d4d;
        }
    }
}

.messageContent {
    line-height: 1.5;
    white-space: pre-wrap;
    width: 100%;

    .platform {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        color: #8E8EA0;
        padding: 0.25rem 0.5rem;
        background-color: rgba(142, 142, 160, 0.1);
        border-radius: 0.25rem;
        display: inline-block;
    }
}

.imageContainer {
    position: relative;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    max-width: 400px;

    &:hover .downloadButton {
        opacity: 1;
    }
}

.uploadedImage {
    width: 100%;
    height: auto;
    display: block;
}

.downloadButton {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: rgba(16, 163, 127, 0.9);
    border: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0;
    padding: 0.6rem;
    
    &:hover {
        background-color: rgba(14, 144, 111, 1);
        transform: scale(1.1);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #8E8EA0;
    font-style: italic;
}

.inputContainer {
    position: sticky;
    bottom: 0;
    background-color: #343541;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-top: 1px solid rgba(255,255,255,0.1);
}

.imagePreviewContainer {
    position: relative;
    width: fit-content;
    max-width: 200px;
    margin-bottom: 0.5rem;
}

.imagePreview {
    max-width: 100%;
    height: auto;
    border-radius: 0.375rem;
    border: 1px solid rgba(255,255,255,0.1);
}

.removeImageButton {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #343541;
    border: 1px solid rgba(255,255,255,0.1);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 0;
    
    &:hover {
        background-color: #444654;
    }
}

.inputWrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.input {
    flex: 1;
    padding: 0.75rem 1rem;
    background-color: #40414F;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 0.375rem;
    color: #FFFFFF;
    font-size: 1rem;
    
    &:focus {
        outline: none;
        border-color: rgba(255,255,255,0.3);
    }
    
    &::placeholder {
        color: #8E8EA0;
    }

    &:disabled {
        background-color: #2D2E3A;
        cursor: not-allowed;
    }
}

.uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    background-color: #40414F;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover:not(:disabled) {
        background-color: #4A4B5A;
    }

    &:disabled {
        background-color: #2D2E3A;
        cursor: not-allowed;
        opacity: 0.7;
    }
}

.fileInput {
    display: none;
}

.uploadIcon {
    width: 1.5rem;
    height: 1.5rem;
    color: #FFFFFF;
}

.sendButton {
    padding: 0.75rem 1.5rem;
    background-color: #10A37F;
    border: none;
    border-radius: 0.375rem;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover:not(:disabled) {
        background-color: #0E906F;
    }
    
    &:disabled {
        background-color: #1A4A3E;
        cursor: not-allowed;
        opacity: 0.7;
    }
} 